define(['app', 'componentHelper', '$console', '$window', 'accessibilityAnnouncer'], (app, componentHelper, $console, $window, accessibilityAnnouncer) => {

  const responsiveFlyoutBasket = () => {
    const component = {};

    const _config = {
      selectors: {
        basketHeaderButton: '.responsiveFlyoutBasket_basketButton',
        accountHeaderButton: '.responsiveAccountHeader_openAccountButton',
        basketHeaderButtonMobile: '#responsiveFlyoutBasket_openBasketButtonMobile',
        accountHeaderButtonMobile: '.responsiveAccountHeader_openAccountButtonMobile',
        basketOverlay: '.responsiveFlyoutBasket_overlay',
        deviceStateEl: '[data-device-state]',
        continueShoppingButton: '[data-close-basket]',
        basketSummaryButton: '[data-js-element=responsiveFlyoutBasket_basketSummary_link]',
        basketItems: '[data-scroll]',
        openFlyoutPanel: '[data-js-element=responsiveFlyoutBasket_openBasketPanel]',

      },
      classNames: {
        basketHeaderButtonExpanded: 'responsiveFlyoutBasket_openBasketButtonMobile-expanded',
        basketSummaryShadowClass: 'responsiveFlyoutBasket_basketSummary-shadow',
      },
    };

    const _init = (element) => {
      component.element = element;
      component.basketHeaderButton = component.element.querySelector(component.config.selectors.basketHeaderButton);
      component.accountHeaderButton = document.querySelector(component.config.selectors.accountHeaderButton);
      component.basketHeaderButtonMobile = component.element.querySelector(component.config.selectors.basketHeaderButtonMobile);
      component.accountHeaderButtonMobile = document.querySelector(component.config.selectors.accountHeaderButtonMobile);
      component.deviceStateEl = component.element.querySelector(component.config.selectors.deviceStateEl);
      component.deviceState = window.getComputedStyle(component.deviceStateEl, ':before').getPropertyValue('content').replace(/\"/g, '');
      component.basketOverlay = document.querySelector(component.config.selectors.basketOverlay);
      component.continueShoppingButton = component.element.querySelectorAll(component.config.selectors.continueShoppingButton);
      component.basketSummaryButton = component.element.querySelector(component.config.selectors.basketSummaryButton);
      component.basketItemsContainer = component.element.querySelector(component.config.selectors.basketItems);
      component.openFlyoutPanel = component.element.querySelector(component.config.selectors.openFlyoutPanel);

      component.headerBreakpoint = 1200;
      component.windowWidthPrevious = component.getWindowWidth();
      component.mobileLayout = component.windowWidthPrevious < component.headerBreakpoint;

      component.updateResponsiveAccountHeaderOpenAccountButtonMobileId();

      app.subscribe('ajaxBasket/updateGlobalBasketItemsCount', component.ajaxBasketItems);
      app.subscribe('globalBasketItemsCount/updateGlobalBasketItemsCount', component.ajaxBasketItems);
      app.subscribe('header/closeBasketResponsive', component.toggleBasketFlyoutMenu);

      component.bind();

      return component;
    };

    const _bind = () => {
      if (component.continueShoppingButton) {
        Array.from(component.continueShoppingButton).map(el => el.addEventListener('click', component.toggleBasketFlyoutMenu));
      }

      if (component.basketItemsContainer) {
        component.basketItemsContainer.addEventListener('scroll', component.addRemoveShadow);
      }

      const focusIn = (e) => {
        component.basketHeaderButton.setAttribute('data-focused', true);
        component.basketHeaderButton.setAttribute('aria-expanded', false);
        component.basketHeaderButton.addEventListener('click', (i) => component.toggleBasketExpanded(e, i));
      }

      component.basketHeaderButton.addEventListener('focusin', focusIn);
      document.addEventListener('focusin', (e) => component.toggleBasketExpanded(e));
      document.addEventListener('focusout', (e) => component.toggleBasketExpanded(e));

      component.basketHeaderButtonMobile.addEventListener('click', component.toggleBasketFlyoutMenu);
      component.basketOverlay.addEventListener('click', component.toggleBasketFlyoutMenu);

      component.deviceStateCondition(component.deviceState);
      window.addEventListener('resize', component.resizeEvent);
    };

    const accountActiveClassList = [
      'responsiveFlyoutBasket',
      'responsiveFlyoutBasket_basketItemsList',
      'responsiveFlyoutBasket_basketDropdown',
      'responsiveFlyoutBasket_basketSummary_wrapper'
    ];

    const allowDropdownClass = 'allow-dropdown';

    const _toggleBasketExpanded = (focusEvent, clickEvent) => {
      const partOfDropdown = accountActiveClassList.includes(focusEvent.target.parentNode.parentNode.className.replace(' ', ''));
      if (!partOfDropdown) {
        if (
          component.basketHeaderButton.getAttribute('aria-expanded') === 'true' ||
          (component.openFlyoutPanel && component.openFlyoutPanel.classList.contains(allowDropdownClass))) {
          component.basketHeaderButton.setAttribute('aria-expanded', false);
          component.basketHeaderButton.setAttribute('data-focused', false);
          component.openFlyoutPanel.classList.remove(allowDropdownClass);
        }
        return;
      }

      const isNotMouseClick = clickEvent && clickEvent.pointerType !== 'mouse';
      if (isNotMouseClick) clickEvent.preventDefault();

      const expanded = component.basketHeaderButton.getAttribute('data-focused') && ![null, undefined].includes(clickEvent);

      component.basketHeaderButton.setAttribute('aria-expanded', `${expanded}`);
      if (!component.openFlyoutPanel) return;

      const hasAllowClass = component.openFlyoutPanel.classList.contains(allowDropdownClass);

      if (expanded && !hasAllowClass) {
        component.openFlyoutPanel.classList.add(allowDropdownClass);
        accessibilityAnnouncer.announce('polite', component.basketHeaderButton.querySelector('.responsiveFlyoutBasket_name').textContent + ' expanded');
      }
    }

    const _scrollPosition = () => (component.basketItemsContainer.scrollTop);

    const _addRemoveShadow = () => {
      if (component.scrollPosition() > 5) {
        component.basketSummaryButton.classList.add(component.config.classNames.basketSummaryShadowClass);
      } else {
        component.basketSummaryButton.classList.remove(component.config.classNames.basketSummaryShadowClass);
      }
    };

    const _resetScrollPosition = () => {
      component.basketItemsContainer.scrollTop = 0;
      app.publish('columbo/track', 'responsiveFlyoutBasket', 'hover', 'desktop open');
    };

    const _ajaxBasketItems = () => {
      var url = '/responsiveFlyoutBasket.bsk';
      if (component.element.baseURI.includes('worthByPrice') || component.element.baseURI.includes('noWorth')) {
        url = url + '?worthValue=worthByPrice';
      }
      app.ajax.get({
        url: url,
        dataType: 'JSON',
        success: component.updateBasketItems,
        error: component.updateBasketItemsError,
      });
    };

    const _updateBasketItems = (response) => {
      const parent = component.element.parentElement;
      if (parent) {
        app.clear('globalBasketItemsCount/updateGlobalBasketItemsCount');
        parent.innerHTML = response;
        componentHelper.reloadAllComponents(parent);
      }
    };

    const _updateBasketItemsError = (response) => {
      $console.error(response);
    };

    const _toggleBasketFlyoutMenu = () => {
      component.expanded = !(component.basketHeaderButtonMobile.getAttribute('aria-expanded') === 'true');

      if (component.accountHeaderButtonMobile.getAttribute('aria-expanded') === 'true') {
        app.publish('header/closeAccountResponsive');
      }

      component.basketHeaderButtonMobile.setAttribute('aria-expanded', component.expanded);

      if (component.basketItemsContainer) {
        component.resetScrollPosition();
      }
      if (component.expanded) {
        document.addEventListener('keydown', component.escapeListener);
        document.body.classList.add('popup-no-scroll');
      } else {
        document.removeEventListener('keydown', component.escapeListener);
        document.body.classList.remove('popup-no-scroll');
      }
    };

    const _escapeListener = event => {
      if (event.key === 'Escape' || event.key === 'Esc') {
        component.toggleBasketFlyoutMenu();
      }
    };

    const _getWindowWidth = () => {
      return $window.innerWidth;
    };

    const _resizeEvent = () => {
      const state = window.getComputedStyle(component.deviceStateEl, ':before').getPropertyValue('content').replace(/\"/g, '');

      component.deviceStateCondition(state);

      component.windowWidthCurrent = component.getWindowWidth();

      if (component.mobileLayout && component.windowWidthCurrent > component.headerBreakpoint && component.expanded) {
        component.toggleBasketFlyoutMenu();
      }

      component.mobileLayout = component.windowWidthCurrent < component.headerBreakpoint;
    };

    const _deviceStateCondition = (state) => {
      if (!component.basketItemsContainer) {
        return;
      }

      if (state === 'desktop') {
        component.openFlyoutPanel.addEventListener('mouseenter', component.resetScrollPosition);
      } else {
        component.openFlyoutPanel.removeEventListener('mouseenter', component.resetScrollPosition);
      }
    };

    const _updateResponsiveAccountHeaderOpenAccountButtonMobileId = () => {
      component.accountHeaderButtonMobile.id="responsiveAccountHeader_openAccountButtonMobile_rightSection"
    }

    component.config = _config;
    component.init = _init;
    component.bind = _bind;
    component.scrollPosition = _scrollPosition;
    component.addRemoveShadow = _addRemoveShadow;
    component.resetScrollPosition = _resetScrollPosition;
    component.ajaxBasketItems = _ajaxBasketItems;
    component.updateBasketItems = _updateBasketItems;
    component.updateBasketItemsError = _updateBasketItemsError;
    component.toggleBasketFlyoutMenu = _toggleBasketFlyoutMenu;
    component.toggleBasketExpanded = _toggleBasketExpanded;
    component.getWindowWidth = _getWindowWidth;
    component.resizeEvent = _resizeEvent;
    component.deviceStateCondition = _deviceStateCondition;
    component.escapeListener = _escapeListener;
    component.updateResponsiveAccountHeaderOpenAccountButtonMobileId = _updateResponsiveAccountHeaderOpenAccountButtonMobileId;

    return component;
  };

  return responsiveFlyoutBasket;
});
